// const config = {
//   siteUrl: "https://portal.ticketmagic.me/",
//   apiUrl: "https://portal.ticketmagic.me/ae",
//   region: "uae",
//   currency: "AED",
//   loclangSlugs: [{ slug: "en" }, { slug: "ar" }],
//   contacts: {
//     instagram: "https://www.instagram.com/ticketmagicdxb/",
//     twitter: "https://www.twitter.com/ticketmagicdxb/",
//     facebook: "https://www.facebook.com/Ticket-Magic-337007016719367/",
//     linkedIn: "https://www.linkedin.com/company/ticketmagic/",
//     youtube: "https://www.youtube.com/channel/UCQ3cYH8epJ06sn5AeCbisww",
//     whatsapp: "+971524039256",
//     phone: "+971524039576",
//     mail: "support@ticketmagic.me",
//   },
// };

// const config = {
//   siteUrl: "https://portal.ticketmagic.me/",
//   apiUrl: "https://portal.ticketmagic.me/sa",
//   region: "saudi",
//   currency: "SAR",
//   loclangSlugs: [{ slug: "en" }, { slug: "ar" }],
//   contacts: {
//     instagram: "https://www.instagram.com/ticketmagicksa/",
//     twitter: "https://www.twitter.com/ticketmagicdxb/",
//     facebook: "https://www.facebook.com/Ticket-Magic-337007016719367/",
//     linkedIn: "https://www.linkedin.com/company/ticketmagic/",
//     youtube: "https://www.youtube.com/channel/UCQ3cYH8epJ06sn5AeCbisww",
//     whatsapp: "+971527064878",
//     phone: "971527064878",
//     mail: "info@ticketmagic.me",
//   },
// };

const config = {
  siteUrl: "https://portal.ticketmagic.me/",
  apiUrl: "https://portal.ticketmagic.me/ca",
  region: "canada",
  currency: "CAD",
  loclangSlugs: [{ slug: "en" }, { slug: "ar" }],
  contacts: {
    instagram: "https://www.instagram.com/ticketmagicdxb/",
    twitter: "https://www.twitter.com/ticketmagicdxb/",
    facebook: "https://www.facebook.com/Ticket-Magic-337007016719367/",
    linkedIn: "https://www.linkedin.com/company/ticketmagic/",
    youtube: "https://www.youtube.com/channel/UCQ3cYH8epJ06sn5AeCbisww",
    whatsapp: "+971524039256",
    phone: "+1(416) 271-4017",
    mail: "support@ticketmagic.me",
  },
};

// const config = {
//   siteUrl: "https://portal.ticketmagic.me/",
//   apiUrl: "https://portal.ticketmagic.me/eg",
//   region: "egypt",
//   currency: "EGP",
//   loclangSlugs: [{ slug: "en" }, { slug: "ar" }],
//   contacts: {
//     instagram: "https://www.instagram.com/ticketmagicdxb/",
//     twitter: "https://www.twitter.com/ticketmagicdxb/",
//     facebook: "https://www.facebook.com/Ticket-Magic-337007016719367/",
//     linkedIn: "https://www.linkedin.com/company/ticketmagic/",
//     youtube: "https://www.youtube.com/channel/UCQ3cYH8epJ06sn5AeCbisww",
//     whatsapp: "+971527064878",
//     phone: "971527064878",
//     mail: "info@ticketmagic.me",
//   },
// };

// const config = {
//   siteUrl: "https://portal.ticketmagic.me/",
//   apiUrl: "https://portal.ticketmagic.me/om",
//   region: "Oman",
//   currency: "AED",
//   loclangSlugs: [{ slug: "en" }, { slug: "ar" }],
//   contacts: {
//     instagram: "https://www.instagram.com/ticketmagicdxb/",
//     twitter: "https://www.twitter.com/ticketmagicdxb/",
//     facebook: "https://www.facebook.com/Ticket-Magic-337007016719367/",
//     linkedIn: "https://www.linkedin.com/company/ticketmagic/",
//     youtube: "https://www.youtube.com/channel/UCQ3cYH8epJ06sn5AeCbisww",
//     whatsapp: "+971524039256",
//     phone: "+971524039576",
//     mail: "support@ticketmagic.me",
//   },
// };

export default config;
